import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SiAccuweather } from "react-icons/si";
import { TiWeatherDownpour, TiWeatherCloudy, TiWeatherWindy } from "react-icons/ti";
import './weather.css';

function WeatherPage(props) {
  const [weather, setWeather] = useState(null);
  const [locationName, setLocationName] = useState('');
  const [region, setRegion] = useState('');
  const [background, setBackground] = useState('');
  const [conditionIcon, setConditionIcon] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState('');
  const [showWindChill, setShowWindChill] = useState(true);

  const items = [
    { name: "Temperature", key: "temperature" },
    { name: "Humidity", key: "humidity" },
    { name: "Wind Speed", key: "wind_speed" },
    { name: "Feels Like", key: "feels_like" },
    { name: "Wind Chill", key: "wind_chill" },
    { name: "Condition", key: "condition" }
  ];

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const apiKey = '0e41b23d445b4d7cbfa180133251901';
        const latitude = props.locationProp[0].coordinates[0];
        const longitude = props.locationProp[0].coordinates[1];
        const weatherUrl = `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${latitude},${longitude}&aqi=no`;

        const response = await axios.get(weatherUrl);
        const data = response.data;

        if (data && data.current) {
          const temperature = data.current.temp_c;
          const windChill = data.current.windchill_c;
          const conditionText = data.current.condition.text.toLowerCase();
          const humidity = data.current.humidity;
          const windSpeed = data.current.wind_kph;

          // Logic to control visibility of wind chill
          const windChillDisplay = temperature <= 10 || data.current.wind_kph > 20 ? `${windChill}°C` : null;

          // Set weather data
          setWeather({
            temperature: `${temperature}°C`,
            humidity: `${humidity}%`,
            condition: data.current.condition.text,
            feels_like: `${data.current.feelslike_c}°C`,
            wind_speed: `${windSpeed} km/h`,
            wind_chill: windChillDisplay,
          });

          setLocationName(data.location.name);
          setRegion(data.location.region);

          // Temperature-based background logic
          if (temperature > 35) {  // Very Hot temperature
            setBackground('linear-gradient(135deg,rgb(255, 167, 34),rgb(230, 165, 25))');  // Darker orange gradient
            setConditionIcon(<SiAccuweather size={40} color="#FF5722" />);
          } else if (temperature > 30) {  // Hot temperature
            setBackground('linear-gradient(135deg,rgb(255, 214, 67),rgb(229, 152, 9))');  // Slightly less bright orange
            setConditionIcon(<SiAccuweather size={40} color="#FF7043" />);
          } else if (temperature > 20) {  // Warm temperature
            setBackground('linear-gradient(135deg, #FFEB3B, #FF9800)');  // Light yellow-orange gradient
            setConditionIcon(<SiAccuweather size={40} color="#FF9800" />);
          } else if (temperature > 10) {  // Cool temperature
            setBackground('linear-gradient(135deg, #03A9F4, #2196F3)');  // Cool blue gradient
            setConditionIcon(<SiAccuweather size={40} color="#2196F3" />);
          } else {  // Very cool temperature
            setBackground('linear-gradient(135deg, #03A9F4, #0288D1)');  // Cooler blue gradient
            setConditionIcon(<TiWeatherCloudy size={40} color="#0288D1" />);
          }

          // Wind-based background adjustments
          if (windSpeed > 20) {
            setAnimationClass('windy');
          }

          // Show Wind Chill only if relevant (temperature <= 10°C or wind speed high)
          setShowWindChill(temperature <= 10 || windSpeed > 20);
        }
      } catch (error) {
        console.error('Error fetching weather data', error);
      }
    };

    fetchWeatherData();
  }, [props.locationProp]);

  // Rotate carousel items every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [items.length]);

  const filteredItems = items.filter(item => item.name !== "Wind Chill" || showWindChill);

  return (
    <div className="weather-widget" style={{ background }}>
      {animationClass && (
        <div className={`animation-container ${animationClass}`}>
          {[...Array(100)].map((_, i) => (
            <div key={i} className="animation-drop" style={{ left: `${Math.random() * 100}%`, animationDelay: `${Math.random() * 5}s` }}></div>
          ))}
        </div>
      )}

      <div className="weather-header">
        <h4>{locationName}, {region}</h4>
      </div>
      <div className="weather-carousel">
        {conditionIcon}
        {weather ? (
          <div className="weather-item">
            <h2>{filteredItems[currentIndex]?.name}</h2>
            <p id="wnumber">{weather[filteredItems[currentIndex]?.key]}</p>
          </div>
        ) : (
          <p>Loading weather data...</p>
        )}
      </div>
    </div>
  );
}

export default WeatherPage;
