import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginSliceState } from './state/signUpSlice';
import submitForm from './Utilities/submitForm';
import { Button, Form, InputGroup, FormControl, DropdownButton, Dropdown,Card } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner';
import Modal from 'react-modal';
import { selectLogin } from 'state/loginSlice';
const SignUp = () => {
  const dispatch = useDispatch();

  const [role, setRole] = useState('Admin');
  const [site, setSite] = useState('All');
  const [type, setType] = useState('All');
  const [showNotice, setShowNotice] = useState(false);
  const [notice, setNotice] = useState({ header: '', message: '' });
  const [showLoader, setShowLoader] = useState(false);

  const { email, password, firstName, lastName, username } = useSelector(selectLogin);

  const submitInput = useRef(null);

  const updateEmail = (event) => {
    const { value } = event.target;
    dispatch(setLoginSliceState(['email', value]));
  };

  const updatePassword = (event) => {
    const { value } = event.target;
    dispatch(setLoginSliceState(['password', value]));
  };

  const updateUsername = (event) => {
    const { value } = event.target;
    dispatch(setLoginSliceState(['username', value]));
  };

  const updateFirstname = (event) => {
    const { value } = event.target;
    dispatch(setLoginSliceState(['firstName', value]));
  };

  const updateLastname = (event) => {
    const { value } = event.target;
    dispatch(setLoginSliceState(['lastName', value]));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoader(true);

    submitForm(
      'signUp',
      email,
      password,
      firstName,
      lastName,
      username,
      role,
      site,
      type,
      setNotice
    );
  };

  return (
    <div className='main-add-user'>
      <div style={{ width: '30%' }}>
        <Card>
          <Oval
            color="#00BFFF"
            height={80}
            width={80}
            visible={showLoader || false}
            style={{
              zIndex: '4',
              position: 'fixed',
              marginTop: '-20%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
          <Card.Header>
            <h4>{'Sign Up'}</h4>
          </Card.Header>
          <Card.Body>
            <form onSubmit={handleSubmit}>
              {/* Email */}
              <div>
                <label>Email ID</label>
                <InputGroup className="mb-1">
                  <FormControl
                    onChange={updateEmail}
                    placeholder="Email ID"
                    required
                    type="email"
                    value={email || ''}
                  />
                </InputGroup>
              </div>

              {/* Name */}
              <div>
                <label>Name</label>
                <InputGroup className="mb-1">
                  <FormControl
                    placeholder="First Name"
                    onChange={updateFirstname}
                    required
                    value={firstName || ''}
                  />
                  <FormControl
                    placeholder="Last Name"
                    onChange={updateLastname}
                    required
                    value={lastName || ''}
                  />
                </InputGroup>
              </div>

              {/* Username */}
              <div>
                <label>Username</label>
                <InputGroup className="mb-1">
                  <FormControl
                    placeholder="Username"
                    onChange={updateUsername}
                    required
                    value={username || ''}
                  />
                </InputGroup>
              </div>

              {/* Role */}
              <div>
                <label>Role</label>
                <DropdownButton
                  variant="outline-primary"
                  title={role}
                  onSelect={(e) => setRole(e)}
                >
                  <Dropdown.Item eventKey="Admin">Admin</Dropdown.Item>
                  <Dropdown.Item eventKey="Head">Head</Dropdown.Item>
                  <Dropdown.Item eventKey="User">User</Dropdown.Item>
                  <Dropdown.Item eventKey="Site Owner">Site Owner</Dropdown.Item>
                  <Dropdown.Item eventKey="End User">End User</Dropdown.Item>
                </DropdownButton>
              </div>

              {/* Password */}
              <div>
                <label>Password</label>
                <InputGroup>
                  <FormControl
                    autoComplete="on"
                    minLength="6"
                    onChange={updatePassword}
                    placeholder="Password"
                    required
                    type="password"
                    value={password || ''}
                  />
                </InputGroup>
              </div>

              {/* Location */}
              <div>
                <label>Location</label>
                <DropdownButton
                  variant="outline-primary"
                  title={site}
                  onSelect={(e) => {
                    setSite(e);
                    dispatch(setLoginSliceState(['site', e]));
                  }}
                >
                  <Dropdown.Item eventKey="All Location">All Location</Dropdown.Item>
                  <Dropdown.Item eventKey="Kuanrmunda">Kuanrmunda</Dropdown.Item>
                  <Dropdown.Item eventKey="Bhojpur Pass">Bhojpur Pass</Dropdown.Item>
                  <Dropdown.Item eventKey="None">None</Dropdown.Item>
                </DropdownButton>
              </div>

              {/* Sensor Type */}
              <div>
                <label>Sensor Type</label>
                <DropdownButton
                  variant="outline-primary"
                  title={type}
                  onSelect={(e) => {
                    setType(e);
                    dispatch(setLoginSliceState(['type', e]));
                  }}
                >
                  <Dropdown.Item eventKey="All Meters">All Meters</Dropdown.Item>
                  <Dropdown.Item eventKey="Energy Meter">Energy Meter</Dropdown.Item>
                  <Dropdown.Item eventKey="Leakage Sensor">Leakage Sensor</Dropdown.Item>
                  <Dropdown.Item eventKey="None">None</Dropdown.Item>
                </DropdownButton>
              </div>

              {/* Submit Button */}
              <div className="d-flex justify-content-center">
                <Button
                  className="btn-primary"
                  variant="large"
                  type="submit"
                  style={{ marginTop: '5%', marginBottom: 0 }}
                >
                  Submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>

      {/* Modal for notice */}
      <Modal
        isOpen={showNotice || false}
        className="Modal"
        style={{
          minHeight: '50%',
          width: '50%',
          alignItems: 'center',
          overlay: { zIndex: 1000 },
        }}
        ariaHideApp={false}
      >
        <p style={{ marginTop: '18%', textAlign: 'center' }}>{notice.message}</p>
        <Button onClick={() => setShowNotice(false)} style={{ marginLeft: '44%' }}>
          Okay
        </Button>
      </Modal>
    </div>
  );
};

export default SignUp;
